import React from 'react';
   import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
   import './App.css';
   import NavBar from './NavBar';
   import Home from './Home';
   import CV from './CV';

   const App = () => {
     return (
       <Router basename="/">
         <div className="webpage">
           <NavBar />
           <Routes>
             <Route path="/" element={<Home />} />
             <Route path="/cv" element={<CV />} />
           </Routes>
         </div>
       </Router>
     );
   };

   export default App;