import React from 'react';
import './CV.css';

const CV = () => {
  return (
    <div className="cv-container">
      <h1>Ida Mayer - Curriculum Vitae</h1>
      

      <section id="neuro">
        <h2>Research Background</h2>
        <ul>
          <li>
            <h3>Research Collaborator, Berkeley Neuroeconomics Lab, 2020-present</h3>
            <p>
            I've done research with this lab looking at influence of prospective reward on motivational behavior, and at relating EEG activity to real time tracking of expectations and reward prediction errors. My current project is focused on stereotypes in social competition. I do my mathematical modeling in R, data anlysis in R and python, and build custom experiment frameworks in TypeScript with React. See more about the lab <a href="https://neuroecon.berkeley.edu/people/">here</a>.</p>
          </li>
        </ul>
      </section>

      <section>
        <h2>Publications</h2>
        <ul>
          <li>Marciano, D., Bellier, L., Mayer, I. et al. Dynamic expectations: Behavioral and electrophysiological evidence of sub-second updates in reward predictions. Commun Biol 6, 871 (2023). <a href="https://www.nature.com/articles/s42003-023-05199-x">https://www.nature.com/articles/s42003-023-05199-x</a></li>
          { /*<li>acr conference and conference in october */}
        </ul>
      </section>



      <section>
        <h2>Performances</h2>
        <ul>
            <h3>Acrobatic Storytelling at the Brava Theatre</h3>
          <li> <i>Pygmalion</i> - 2018, Trio Acrobatics.{/*(photo gallery/links)*/}</li>
          <h3>SF Movement Arts Festival at Grace Cathedral</h3>
          <li><i>Gold Railing</i> - 2020, Audience interactive LED dance piece; Stage Manager.</li>
          <li><i>The Bell</i> {/*(photo gallery/links)*/} - 2019, Partner Acrobatics. </li>
          <li><i>Ambient</i> {/*(photo gallery/links)*/} - 2019, Trio Acrobatics. </li>
          <h3>Bad Influences Burlesque at the San Jose Improv</h3>
          <li> <i>Santa Baby</i> - 2018, Partner acrobatics.</li>
          <li> <i>Ghostbusters</i> - 2018, Contortion and partner acrobatics. </li> {/** Also performed at that other burlesque show?? */}
          <li> <i>Evil Clowns</i> - 2018, Halloween partner acrobatics routine. </li>
          <li> <i>Game On!</i> - 2018, Mario themed group partner acrobatics act.</li>
          <h3>Uptown Cabaret at the Uptown Night Club</h3>
          <li><i>Evil Clowns</i> - 2018, Halloween partner acrobatics routine. </li>
          <li><i>Ghostbusters</i> - 2018, Contortion and partner acrobatics. </li>
          <h3>Google, Inc</h3>
          <li><i>Parents' Day Presentation</i> - 2019, Partner contortion.</li>
          <li><i>Cirque du Google Student Showcase</i> - 2019, Partner acrobatics instructor; Co-director; <i>The Mechanic</i> partner acrobatics and aerial silks performer.</li>
          <li><i>Cirque du Google Student Showcase</i> - 2017, Partner acrobatics instructor; Director; Acroyoga group act. Parnter aerial silks act.</li>
          <li><i>National Dance Week</i> - 2017, Partner acrobatics.</li>
          <li><i>Cirque du Google Student Showcase</i> - 2016, Partner acrobatics instructor; Intro aerial silks instructor; Director; Acroyoga group act. Parnter aerial silks act.</li>
          <li> {/*for more info see my (LINK) acro page or */} Come <a href="/classes">train with me at Athletic Playground</a></li>
          {/** Taught acro at Google 2015-2018
I’ve also taught at the 2016 ACROnym acroyoga festival, and was a guest instructor at Max Dance Company for the company troupe during Spring quarter of 2017.
 Dance lifts workshop at AP in 2022. currently flexibility at AP 2023-present.*/}
        </ul>
      </section>


      <section>
        <h2>Patents</h2>
        <ul>
          <li><i>Location-based reminder process</i>, <a href="https://patents.google.com/patent/US9247522B1/en">Patent number: US9247522B1</a>, granted 2016 </li>
          <li><i>Remote invocation of mobile device actions</i>, <a href="https://patents.google.com/patent/US10152987B2/en">Patent number: US10152987B2</a>, granted 2018</li>
        </ul>
      </section>

      <section id="google">
        <h2>Google Career</h2>
        <ul>
          <li>
            <h3>Senior UX Engineer, Google, 2019-2021<br/>
            Senior Software Engineer, Google, 2011-2018</h3>
            <p>I joined Google when there were less than 5,000 people there, and I've had the opportunity to work on some pretty fun things that I think a lot of people have ended up using.
                The things that most people have seen are probably in 2013 when I rebuilt the toolbar on search along with one other engineer (this must have been touched by a billion people by now), and I added doodles to our mobile site (you know, that thing where the Google logo changes for holidays) - I also worked with some incredible designers on the <a href="https://www.google.com/doodles/ada-lovelaces-197th-birthday">Ada Lovelace Doodle</a>!  
                I was involved in migrating all of google's frontend from C++ to Java. And I worked on an early version of the Google Assistant. When I worked on Travel Search, my work was written up in Our product has been written up in <a href="http://www.nytimes.com/2016/03/13/travel/trip-planner-google-destinations.html?_r=0">the NY Times</a>, <a href="https://techcrunch.com/2016/09/19/google-destinations-expands-to-the-desktop-rolls-out-worldwide/">TechCrunch</a>, and <a href="http://www.buzzfeed.com/nicolenguyen/google-travel-and-chill#.baAem6vDa">Buzzfeed</a>.

                { /** ALSO TRAVEL AND MEDIA COVERAGE */}
            </p>
          </li>
        
        </ul>
      </section>



      <section>
        <h2>Education</h2>
        <ul>

          <h4>MSc in Cognitive Neuroscience, Freie Universität Berlin, 2022</h4>
          <p>My thesis topic was on motivation, titled <i>Trajectory and Perceived Velocity in Motivation for Cognitive Tasks</i>, advised by <a href="https://neuroecon.berkeley.edu/people/">Prof. Ming Hsu of UC Berkeley</a> and <a href="www.decision-neuroscience.eu">Prof. Peter N.C. Mohr of Freie Universität</a>. You can see my thesis defense slides <a href="https://docs.google.com/presentation/d/1zt8b2YIOJFXeiIoCm52bD5wF4fiSME-pFo65GBBDv3M/edit?usp=sharing">here</a>.</p>

          <h4>BS Computer Science, Carnegie Mellon University, 2011<br/>
BA Creative Writing, Carnegie Mellon University, 2011<br/>
BA Multilingual Studies, Carnegie Mellon University, 2011</h4>
<p>I graduated summa cum laude and with honors, with a senior thesis on magical realism in modern japanese literature, and was inducted into <a href="https://www.cs.cmu.edu/~scsfacts/phibetakappa.html">Phi Beta Kappa</a>. My favorite courses have been in algorithms, distributed systems, neuroscience, HCI, communication design, German, and Mandarin.</p>
        </ul>
      </section>



      <section>
        <h2>Awards and Honors</h2>
        <ul>
            {/**
          <li>Google Peer Bonus Award ..................... </li>
          <li>Berlin scholarship? .............</li>
 */}
            <h4>Carnegie Mellon University</h4>
          <li>
            <a href="https://www.scs.cmu.edu/~scsfacts/carnegie.html">Andrew Carnegie Scholars</a>, 2011</li>
            <li> <a href="https://www.cmu.edu/cmwa/awards/All%20Winners.html">Carnegie Women's Association Award</a>, 2011
 </li>
        </ul>
      </section>


      <section>
        <h2>Languages</h2>
        <ul>
          <li>English (native)</li>
          <li>German (B2 conversational)</li>
          <li>Mandarin Chinese (HSK3 basic)</li>
        </ul>
      </section>

      <section>
        <h2>Trivia</h2>
        <ul>
          <li>I briefly lived in Berlin during the pandemic. </li>
<li>I'm a model and paintings of me have been showcased internationally and sold for upwards of $14k. {/*https://artworldmatch.com/art/aerial-by-henry-asencio*/}</li>
<li>I once made a popular post on reddit about Magic the Gathering that got picked up by the media  <a href="https://www.thegamer.com/counterspell-guesser-magic-the-gathering/">here</a> and <a href="https://www.wargamer.com/magic-the-gathering/counterspell-guesser-game">here</a>. You can play the game <a href="/counterspell-guesser">here</a>.
</li>
<li>I read 100 books in 2022. You can see my favorites <a href="https://www.instagram.com/p/Cm4z0bKSEbt/">here</a>.</li>
<li>I'm named after my great grandmother with whom I share a birthday almost exaclty 100 years apart.
{/*Named after my great-grandmother Ida Shankerman (Magdovitz) born April 11, 1898. I'm born April 11, 1989*/}
</li>
        </ul>
      </section>


      <section>
        <h2>Current Hobbies &amp; Interests</h2>
        <ul>
          <li>singing, learning chinese, basic contortion, semantic vs phonemic tone as well as prosody in mandarin, somatic control of canonically autonomic thermal regulation systems, environmental storytelling</li>
        </ul>
      </section>
    </div>
  );
};

export default CV;
