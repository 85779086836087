import React,  { useState }  from 'react';
import { FaInstagram, FaLinkedin, FaCalendar, FaEnvelope } from 'react-icons/fa';
/*import GalleryItem from './GalleryItem';*/

// Simulating content from a different file
const expandableContent = {
  "Culinary Projects": "I have made a wide variety of creative foods and co-authored a cookbook.",
  "Chinese": "It doesn't make for great photos, but learning spoken mandarin chinese has taken the largest portion of my hobby time of late."
};

const GalleryItem = ({ title, isExpandable, src, link }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const handleClick = () => {
    if (isExpandable) {
      setIsExpanded(true);
      setTimeout(() => setShowContent(true), 300); // Show content after animation
    } else {
      if (link) window.location.href = link;
    }
  };

  const handleClose = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the parent div
    setShowContent(false);
    setTimeout(() => setIsExpanded(false), 300); // Hide item after content fades out
  };

  return (
    <a href={link || '#gallery'} className={`gallery-item ${isExpanded ? 'expanded' : ''} ${showContent ? 'expanded-visible' : ''}`} onClick={handleClick} style={{backgroundImage: `url('${src}')`}}>
      <div className="gallery-item-content">{title}</div>
      {isExpanded && (
        <div className={`expanded-content ${showContent ? 'visible' : ''}`}>
          <h2>{title}</h2>
          <p>{expandableContent[title]}</p>
          <button className="close-button" onClick={handleClose}>&times;</button>
        </div>
      )}
    </a>
  );
};

const Home = () => {
  const galleryItems = [
    { title: "Partner Acrobatics", isExpandable: false, src: "./img/project-imgs/acro.png", link: 'http://www.guns-n-poses.idamayer.com/demo-reel.html' },
    { title: "Circus Performances", isExpandable: false, src: "./img/project-imgs/circus.jpg", link: 'http://www.guns-n-poses.idamayer.com/gallery.html'  },
    { title: "Neuroscience Research", isExpandable: true, src: "./img/project-imgs/neuro.png", link: '/cv#neuro'   },
    { title: "Google Career", isExpandable: true, src: "./img/project-imgs/google.jpg", link: '/cv#google'  },
    { title: "Master's Thesis", isExpandable: false, src: "./img/project-imgs/thesis.jpg", link:'https://docs.google.com/presentation/d/1zt8b2YIOJFXeiIoCm52bD5wF4fiSME-pFo65GBBDv3M/edit?usp=sharing' },
    { title: "Academic Background", isExpandable: false, src: "./img/project-imgs/academic.jpg", link:'/cv' },
    { title: "Valley of Fire Series", isExpandable: false, src: "./img/project-imgs/valleyoffire.jpg"  },
    { title: "Henry Asencio Paintings", isExpandable: false, src: "./img/project-imgs/asencio.jpg"  },
    { title: "SF Evans Portraits", isExpandable: false, src: "./img/project-imgs/sfevans.png"  },
    { title: "Silver Portrait Series", isExpandable: false, src: "./img/project-imgs/silver.jpg"  },
    { title: "Black & White Portrait Series", isExpandable: false, src: "./img/project-imgs/bw.jpg"  },
    { title: "Nude Figure Modeling", isExpandable: false, src: "./img/project-imgs/nude.jpg"  },
    { title: "Modern Games Deck", isExpandable: false, src: "./img/project-imgs/cards.jpg" , link: 'https://idamayer.com/moderngamesdeck/' },
    { title: "Za: The Word Game", isExpandable: false, src: "./img/project-imgs/za.jpg", link: 'https://zathewordgame.wordpress.com/' },
    { title: "Illustrations", isExpandable: false, src: "./img/project-imgs/illustration.png", link: 'https://drive.google.com/drive/folders/1YRjTVnAqyNezzqpXEcGC4ea7dtJfSeCi' },
    { title: "Culinary Projects", isExpandable: true, src: "./img/project-imgs/food.jpg", link: 'https://photos.app.goo.gl/cNJ8bAhFbmmK6fdM6'  },
    { title: "Chinese", isExpandable: false, src: "./img/project-imgs/chinese.jpg"  },
  ];
  
    return (
        <>
        <div className="hero-image" id="home">
            <h1 className="hero-text">Ida Mayer</h1>
            <div>
            <h2 className="hero-text">Friendly Neighborhood Polymath</h2>
            <h3 className="hero-text">Coach &middot; Acrobat &middot; Model &middot; Engineer &middot; Researcher</h3>
            </div>
        </div>

        <div className="button-container">
            <a href="https://coaching.idamayer.com" className="large-button" style={{backgroundImage: 'url("/img/grow-with-me.png")'}}>Grow with me</a>
            <a href="/classes" className="large-button" style={{backgroundImage: 'url("/img/train-with-me.png")'}}>Train with me</a>
            <a href="/cv" className="large-button" style={{backgroundImage: 'url("/img/awards-and-publications.png")'}}>Awards and Publications</a>
        </div>

        <div id="about" className="bio-section">
            <div className="bio-text">
            <h2 style={{display:'inline'}}>I seek out variety</h2>
            <p style={{display:'inline'}}> in my life. The things I'm focusing on right now are running my productivity <a href="https://coaching.idamayer.com">coaching</a> business, teaching and training <a href="/classes">flexibility</a> with the Athletic Playground, and learning mandarin Chinese. 
            I'm a former Google software engineer with a relatively varied academic background - I hold 4 degrees across humanities and STEM fields, and am currently affiliated with Berkeley Neuroeconomics Lab. 
            When I'm away from those interests I love making bizarre fusion foods that perplex friends and strangers alike, and exploring various media of environmental storytelling.</p>
            </div>
            <div className="bio-image">
            <img src="./img/bio.jpg" alt="Ida Mayer" />
            </div>
        </div>

        <div id="gallery" className="gallery">
            {galleryItems.map((item, index) => (
            <GalleryItem key={index} title={item.title} isExpandable={item.isExpandable} src={item.src} link={item.link} />
            ))}
        </div>

        <footer>
            <div className="social-icons">
            <a href="https://instagram.com/idamayeraesthetic" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://linkedin.com/in/idamayer" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            <a href="https://cal.com/idamayer" target="_blank" rel="noopener noreferrer"><FaCalendar /></a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfWCwbly-8mHm4v3Ax5FJARczXtxxqZmz1IG6QDleiW1Gd41Q/viewform"><FaEnvelope /></a>
            </div>
        </footer>
        </>
    );
};

export default Home;